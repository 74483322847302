import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "main" */ '../views/Home.vue')
  },
  {
  path: '/messages',
  name: 'Messages',
  component: () => import(/* webpackChunkName: "main" */ '../views/Messages.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Deposit.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Withdraw.vue')
  },
  {
    path: '/au/share/:symbol',
    name: 'AU Share',
    meta: {
      security_type: 'share.au',
    },
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Security.vue')
  },
  {
    path: '/us/share/:symbol',
    name: 'US Share',
    meta: {
      security_type: 'share.us',
    },
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Security.vue')
  },
  {
    path: '/au/etf/:symbol',
    name: 'AU ETF',
    meta: {
      security_type: 'etf.au',
    },
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Security.vue')
  },
  {
    path: '/crypto/:symbol',
    name: 'Crypto',
    meta : {
      security_type: 'crypto',
    },
    component: () => import(/* webpackChunkName: "transfer" */ '../views/Security.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
