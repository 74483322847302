import { getModule, Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'

@Module
export default class Securities extends VuexModule {
  
  
  price_data: any = {
    'BTCUSD' : {
      price: '1'
    }
  };

  event_callbacks = [];

  count = 0

  @Mutation
  increment(delta: number) {
    this.count += delta
  }
  @Mutation
  decrement(delta: number) {
    this.count -= delta
  }

  // action 'incr' commits mutation 'increment' when done with return value as payload
  @Action({ commit: 'increment' })
  incr() {
    return 5
  }
  // action 'decr' commits mutation 'decrement' when done with return value as payload
  @Action({ commit: 'decrement' })
  decr() {
    return 5
  }

  // Listen for message
  
  wsOnMessage(ev: any) {
		let message = JSON.parse(ev.data);
		
		let event_type = message.type;
		
		if(event_type == "price_update") {
      
      let price_update = 	message.data;
      // this.update_prices(price_update);
      this.update(price_update);

      // this.context.dispatch('update', price_update);
      
      // this.bid = price_update.best_bid;
      // this.ask = price_update.best_ask;
    
      // this.update_quantity();
    } else if(event_type == "order_event") {
      for(ev of this.event_callbacks) {
        // setTimeout((data: any) => {
          ev(message);
        // }, 2000, ev.data);
        
      }
    }
    
  }

  constructor(arg: any) {

    super(arg);

    setInterval(() => {
      // console.log(">", this.price_data);
    }, 1000);

    this.incr();

    // this.ws_stream.addEventListener('message', this.wsOnMessage.bind(this));
  }

  @MutationAction
  async update(prices: any) {
    this.price_data = prices;

      return {
        price_data: prices
      }
  }

  // @Mutation
  // update_prices(prices: any) {
  //   this.price_data = prices
  // }
  
  // // action 'incr' commits mutation 'increment' when done with return value as payload
  // @Action
  // async update(prices: any) {
  //   console.log(this);
  //   this.context.commit('update_prices', prices);
  //   // return prices
  // }

}

// export default getModule(UsersModule);