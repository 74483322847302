// import Vue from 'vue';
// import Vuex from 'vuex';

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
// });


import Vue from 'vue';
import Vuex from 'vuex';

import account from './account';
import securities from './securities';
// import { ISe} from '.securities';
// import { IUserState} from './modules/user';
// import { ITagsViewState} from './modules/tagsView';

// export interface IRootState {
//   app: IAppState;
//   user: IUserState;
//   tagsView: ITagsViewState;
//   role: IRootState;
// }

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {},
  modules: {
    account,
    securities
  }
})

export default store;